import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBlock title="Sue Williams" subtitle="Primary Principal" mdxType="AuthorBlock">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "663px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/aa60f01d7fd3a3a1378dccb1ffe5baeb/b9214/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEBQL/xAAWAQEBAQAAAAAAAAAAAAAAAAADAQD/2gAMAwEAAhADEAAAAcqufWuwjsYaLELFkHBZ/8QAHBAAAgMAAwEAAAAAAAAAAAAAAQIAAwQREhMi/9oACAEBAAEFAkXkvn6rKyFa4jzmdPS3T8CY7BW+h+9s/8QAFxEBAQEBAAAAAAAAAAAAAAAAARACEf/aAAgBAwEBPwHAJB4T/8QAFxEBAQEBAAAAAAAAAAAAAAAAARACEf/aAAgBAgEBPwHajE6z/8QAHBAAAgICAwAAAAAAAAAAAAAAAAECEBEhMkFR/9oACAEBAAY/AjdZZ1SQo+1tciTr/8QAGxAAAwADAQEAAAAAAAAAAAAAAAEhETFRYUH/2gAIAQEAAT8hhsptTAk6Bp9l58Hs8krOUdMnBpWRxng3ivCE4f/aAAwDAQACAAMAAAAQVz2y/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EHq2zYlhf//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxA6DZFq2t//xAAeEAEAAwACAgMAAAAAAAAAAAABABEhMUFxsVFhof/aAAgBAQABPxADah7mVbcJdeI6iC4RSrv6mxIpxwuAtXEcMst4gv3UGPw7sDj99TW6i3SAHwjmEKJ6DJQJ/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/aa60f01d7fd3a3a1378dccb1ffe5baeb/b9214/01.jpg",
              "srcSet": ["/static/aa60f01d7fd3a3a1378dccb1ffe5baeb/f93b5/01.jpg 300w", "/static/aa60f01d7fd3a3a1378dccb1ffe5baeb/b4294/01.jpg 600w", "/static/aa60f01d7fd3a3a1378dccb1ffe5baeb/b9214/01.jpg 663w"],
              "sizes": "(max-width: 663px) 100vw, 663px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <blockquote>
      <p parentName="blockquote">{`It has been a year of new learning for all of us, students, parents and teachers and there has been a lot of it. While our EC through Grade 2 students were on campus everyday for the vast majority of the year, our Grades 3 through 5 students were learning both on campus and off.`}</p>
    </blockquote>
    <p>{`Teachers stepped up their use of technology and not only taught those who were on campus but those who were at home and zooming into lessons all at the same time. Parents were asked to help monitor student work at home, all while many had to work as well. And students logged into lessons when they were asked and worked both synchronously and asynchronously.`}</p>
    <p>{`Not only did we continue to learn and teach literacy and maths skills, students participated in music, art, languages and PSPE. While the pandemic may have forced us to make fast changes in what and how learning took place, it also taught all of us some life long lessons. Such as resilience, patience, adaptability and compassion. Our students became even more independent than in a ‘typical’ school year and were able to adjust quickly to the many changes that were put into place and those that happened overnight.`}</p>
    <p>{`We continued using our reading and writing workshop pedagogy of teaching and learning literacy while also clustering our maths programme to ensure that students learned the essential content and concepts needed to be confident in their next year’s grade level. We will continue to focus on our maths programme in the primary school during the 2021 - 2022 school year and have adopted a maths platform that all teachers will use called Maths in Practice.`}</p>
    <p>{`While it was a challenging year, it was also a year filled with kindness, laughter and comradery. We all helped each other; whether that was students helping their peers use a new platform, teachers sharing ideas and practices, or parents supporting their children at home. For this, I am honoured to have been a part of PSI for the past four years.`}</p>
    <p>{`It has been wonderful getting to know colleagues, families and the students. I will carry many memories of my time here and am sure that Ms. Jan Humbleby, our incoming primary principal, will ensure that the PSI Primary School continues to be a place where you all belong.`}</p>
    <p>{`With kindest regards and wishes for love and laughter wherever your family ‘lands’,`}</p>
    <p><strong parentName="p">{`Sue Williams`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`PSI Primary School Principal`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      